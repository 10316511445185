import React from 'react';
import './ScanWorkOrder.css';
import { AppTools } from '../../types';
import { QRCodeReader } from '../../views';
import { ID_ROUTE, ROOT_ROUTE, UNIQUE_WORK_ORDER_ROUTE } from '../../route.ts';
import { useNavigate } from 'react-router-dom';

type ScanWorkOrderProps = {
    appTools: AppTools
}

const ScanWorkOrder = (props: ScanWorkOrderProps) => {
    const navigate = useNavigate();

    // fonction appelé quand un QR Code est lu
    const onQRCodeRead = (value: string) => {
        navigate(UNIQUE_WORK_ORDER_ROUTE.replace(ID_ROUTE, value));
    }
    
    return (
        <div className='scanwo-main fill-to-screen'>
            <QRCodeReader 
                description="Flasher le QR Code d'un bon"
                handleQrCodeRead={onQRCodeRead}
                onGoBack={() => navigate(ROOT_ROUTE)}
            />
        </div>
    )
}

export default ScanWorkOrder;