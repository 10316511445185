import React, { useEffect, useState } from "react";
import './TmpLoginOnePhone.css'
import { AppTools, Role } from "../../types.ts";
import { QRCodeReader } from "../index.js";
import { tryGetValueOfQrCode } from "../../route.ts";
import config from "../../config.ts";

type TmpLoginOnePhoneProps = {
    isExistUserId: (userId: string) => Promise<boolean>,
    appTools: AppTools,
    neededRole: Role,
    onCanceled?: () => void,
    isReturnDevice?: boolean
}

const TmpLoginOnePhone = (props: TmpLoginOnePhoneProps) => {
    const {isExistUserId, appTools, neededRole, onCanceled, isReturnDevice} = props;

    const [roleTxt, setRoleTxt] = useState<string | undefined>();
    const [relativePronoun, setRelativePronoun] = useState<string | undefined>();
    const [personalPronoun, setPersonalPronoun] = useState<string | undefined>();
    const [QRCodeRead, setQRCodeRead] = useState<boolean>(false);

    useEffect(() => {
        switch(neededRole){
            case Role.Manager:
                setRoleTxt("l'exploitant");
                setRelativePronoun("à");
                setPersonalPronoun("");
                break;
            case Role.Executor:
                setRoleTxt("responsable GIES2")
                setRelativePronoun("au");
                setPersonalPronoun("le");
                break;
            case Role.Fireman:
                setRoleTxt("pompier");
                setRelativePronoun("au");
                setPersonalPronoun("le");
                break;
            case Role.Electrician:
                setRoleTxt("l'électricien");
                setRelativePronoun("à");
                setPersonalPronoun("");
                break;
            case Role.Receptionnists:
                setRoleTxt("validateur");
                setRelativePronoun("au");
                setPersonalPronoun("le");
                break;
        }
    }, []);

    const onQRCodeRead = (text: string) => {
        text = tryGetValueOfQrCode(text);
        setQRCodeRead(false);
        appTools.isLoading(true);
        isExistUserId(text)
        .then((result) => {
            // il n'y a rien à faire, la fonction 'isExistUserId'
            // va changer l'état de connexion et App.tsx se charge de 
            // changer de page 
        })
        .catch((error) => {

        })
        .finally(() => {
            appTools.isLoading(false);
        })
    };

    return (
        <>
            {!QRCodeRead && (
                <div className="tmpLogin-main">
                    {isReturnDevice && (
                        <h1>Retour de l'appareil</h1>
                    )}
                    {!isReturnDevice && (
                        <h1>Signature {roleTxt}</h1>
                    )}
                    <div className="tmpLogin-txt-container">
                        {isReturnDevice && (
                            <p>Donnez l'appareil {relativePronoun} {roleTxt}</p>
                        )}
                        {!isReturnDevice && (
                            <p>Donnez l'appareil {relativePronoun} {roleTxt} afin de procéder à la signature</p>
                        )}
                    </div>
                    <button className="white-button" onClick={() => {
                        setQRCodeRead(true)
                        }}>
                        <p>Je suis {personalPronoun} {roleTxt}</p>
                    </button>
                    {isReturnDevice && onCanceled && (
                        <button className="white-button tmpLogin-cancel-button" onClick={onCanceled}>
                            <p>Annuler retour</p>
                        </button>
                    )}
                    {!isReturnDevice && onCanceled && (
                        <button className="white-button tmpLogin-cancel-button" onClick={onCanceled}>
                            <p>Annuler signature</p>
                        </button>
                    )}
                </div>
            )}

            {QRCodeRead && (
                <QRCodeReader 
                    valueToIncludeToValidateQrCode={config.appNameDomain}
                    description="Flasher le QR Code associé à votre profil" 
                    handleQrCodeRead={onQRCodeRead} 
                    onGoBack={() => setQRCodeRead(false)}
                    />
            )}
        </>
        
    );
};

export default TmpLoginOnePhone;