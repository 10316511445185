import React, { createRef } from "react";
import './SelectRole.css';
import { Role, getImgByRole, getRoleLabel } from '../../types.ts';

type SelectRoleProps = {
    roles: Role[],
    onSelect: (role: Role) => void,
    onCancel: () => void
}

const SelectRole = (props: SelectRoleProps) => {
    const { roles, onSelect, onCancel } = props;

    const mainRef = createRef<HTMLDivElement>();
    const wrapperRef = createRef<HTMLDivElement>();

    const triggerOnClose = (role?: Role) => {
        if(mainRef.current && wrapperRef.current){
            mainRef.current!.style.animationName = "fadeOut";
            wrapperRef.current!.style.animationName = "UpToBottom";
            mainRef.current!.scrollTo({top: 0, behavior: "smooth"});
            setTimeout(() => {
                if(mainRef.current){
                    mainRef.current!.style.opacity = "0";
                }
                if(role){
                    onSelect(role);
                }else{
                    onCancel();
                }
            },400);
        }else{
            if(role){
                onSelect(role);
            }else{
                onCancel();
            }
        }
    }

    return (
        <div ref={mainRef} className="select-role-main">
            <div className="select-role-empty-space" onClick={() => triggerOnClose()}></div>
            <div ref={wrapperRef} className="select-role-wrapper">
                <div className="select-role-container">
                    <div className="select-role-round-square" />
                    <p className="select-role-txt">Selectionner votre rôle pour naviguer dans l'application</p>

                    {roles.map((role, index) => (
                        <div key={index} className="select-role-element" onClick={() => triggerOnClose(role)}>
                            <img src={getImgByRole(role)}/>
                            <p>{getRoleLabel(role)}</p>
                        </div>
                    ))}
                        
                </div>
            </div>
        </div>
    )
}

export default SelectRole;