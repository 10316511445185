interface Config {
    apiBaseUrl: string;
    port: string;
    appNameDomain: string;
}

const config: Config = {
    apiBaseUrl: "https://api.sign.sis-petra.fr",
    port: "3000",
    appNameDomain: "sis-petra.fr"
};

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev code
    console.log('development');
    config.apiBaseUrl = "https://localhost:7137";
    config.appNameDomain = "localhost:3001";
} else {
    // production code
    console.log('production');
    config.apiBaseUrl = "https://api.sign.sis-petra.fr";
    config.appNameDomain = "sis-petra.fr";
}

export default config;