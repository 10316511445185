import React from 'react';
import './GoBack.css';

import { ChevronImg } from '../images';

type GoBackProps = {
    onGoBack: () => void
}

const GoBack = (props: GoBackProps) => {
    return (
        <div className='goback-main'>
            <div className='goback-clickable' onClick={() => {props.onGoBack()}}>
                <img src={ChevronImg} />
                <p className='goback-text'>Revenir</p>
            </div>
        </div>
    );
}

export default GoBack;