import { ReactNode } from "react"
import { AdminRoleImg, ElectricianImg, ExecutorImg, FiremanImg, ManagerImg, ReceptionnistsImg } from "./images"

export enum ConnectState {
    NotConnected = 0,
    HaveUserId = 1,
    Connected = 2
};

export type AppTools = {
    showMessage: (type: MessageViewType, text: string, description?: string) => void,
    isLoading: (visible: boolean) => void
    disconnect: () => Promise<boolean>,
    reloadTimerExpiration:() => void,
    pushDisplay: (display: ReactNode) => void,
    popDisplay: () => void,
    checkUserConnected: () => void,
    displayRoleSelector: (roles: Role[], forceSelect?: boolean) => Promise<Role>,
    displayInput: (title: string, placeholder: string, type: Types.string | Types.number, biggerInput?: boolean) => Promise<string | number>
}

export enum WorkOrderState{
    AwaitingDecision = 0,
    EntryToSign = 1,
    WIP = 2,
    ExitToSign = 3,
    UnfinishedWork = 4,
    WorksToBeApproved = 5,
    WorksApproved = 6,
    Optionnal = 7
}

export enum Types {
    string,
    number,
    boolean,
}


export const getDescriptionStatus = (status: WorkOrderState): string => {
    switch (status) {
        case WorkOrderState.AwaitingDecision:
            return "En attente de déscision";
        case WorkOrderState.EntryToSign:
            return "Entrée à signée";
        case WorkOrderState.WIP:
            return "Travaux en cours";
        case WorkOrderState.ExitToSign:
            return "Sortie à signée";
        case WorkOrderState.UnfinishedWork:
            return "Travaux non terminé";
        case WorkOrderState.WorksToBeApproved:
            return "Travaux à approuver";
        case WorkOrderState.WorksApproved:
            return "Travaux receptionné";
        case WorkOrderState.Optionnal:
            return "Signature optionnelle";
        default:
            return "";
    }
}


export enum WorkOrderType{
    WORK_PERMITS = 'WORK_PERMITS',
    WORK_PERMITS_WITH_RECEPTIONIST = 'WORK_PERMITS_WITH_RECEPTIONIST',
    WORK_PERMITS_WITH_SECUTITY = 'WORK_PERMITS_WITH_SECUTITY',
    WORK_PERMITS_WITH_RECEPTIONIST_AND_SECUTITY = 'WORK_PERMITS_WITH_RECEPTIONIST_AND_SECUTITY',
    WORK_PERMITS_HOT_SPOT = 'WORK_PERMITS_HOT_SPOT',
    WORK_PERMIT_AT_HEIGHT = 'WORK_PERMIT_AT_HEIGHT',
    LIFTING_PERMIT = 'LIFTING_PERMIT',
    DIGGING_PERMIT = 'DIGGING_PERMIT',
    COECOE = 'COECOE',
    PENETRATION_PERMIT = 'PENETRATION_PERMIT',
}

export enum MessageViewType {
    Success,
    Error,
    Warning,
    NotFound,
}

export enum RequestType {
    POST = 'POST',
    GET = 'GET',
    PUT = 'PUT',
    PATCH = 'PATCH',
    DELETE = 'DELETE',
}

export enum Role {
    Executor = 2,
    Manager = 3,
    Fireman = 4,
    Electrician = 5,
    Receptionnists = 6,
    Admin = 1,
    Unknown = 0,
}

export const getImgByRole = (role: Role): string | undefined => {
    switch(role){
        case Role.Electrician:
            return ElectricianImg;
        case Role.Executor:
            return ExecutorImg;
        case Role.Fireman:
            return FiremanImg;
        case Role.Manager:
            return ManagerImg;
        case Role.Receptionnists:
            return ReceptionnistsImg;
        case Role.Admin:
            return AdminRoleImg;
        default:
            return undefined;
    }
};

export const getRoleLabel = (role: Role): string => {
    switch(role){
        case Role.Executor:
            return "Responsable EE (GIES2)";
        case Role.Manager:
            return "Exploitant";
        case Role.Fireman:
            return "Service securité";
        case Role.Electrician:
            return "Service élecrique";
        case Role.Receptionnists:
            return "Réception";
        case Role.Admin:
            return "Administrateur";
        default:
            return "-";
    }
}

export type RequestHeaders = {
    contents: HeaderContent[]
}

export type HeaderContent = {
    label: string,
    content: string
}

export enum SignatureType {
    HALF_DAY_MORNING = 'HALF_DAY_MORNING', 
    HALF_DAY_AFTERNOON = 'HALF_DAY_AFTERNOON', 
    END_HALF_DAY_MORNING = 'END_HALF_DAY_MORNING', 
    END_HALF_DAY_AFTERNOON = 'END_HALF_DAY_AFTERNOON', 
    WORKS_ACCEPTANCE = 'WORKS_ACCEPTANCE',
    OPTIONNAL = 'OPTIONNAL'
}

export type SignState = {
    role: Role,
    isSigned: boolean
}


export enum SignatureTypeDeclaration {
    START_HALF_DAY = 'START_HALF_DAY', 
    END_HALF_DAY = 'END_HALF_DAY', 
    WORK_ACCEPTANCE = 'WORK_ACCEPTANCE',
    // not in API
    OPTIONNAL = 'OPTIONNAL',
    VALIDATE = 'VALIDATE'
}

export enum EpiEnum{
    Unknown = 0,
    Helmet = 1,
    Cap = 2,
    SafetyGlasses = 3,
    SecurityGoggles = 4,
    FaceGlass = 5,
    WeldingMask = 6,
    WeldingHoodVentilated = 7,
    HearingProtection = 8,
    StandardizedWorkClothing = 9,
    AcidSuit = 10,
    DustSuit = 11,
    AirDuctSuit = 12,
    Harness = 13,
    SafetyShoes = 14,
    AcidBoots = 15,
    DustMask = 16,
    PanoramicMask = 17,
    AirDuctHood = 18,
    NeopreneGloves = 19,
    CutGloves = 20,
    LeatherHandlingGloves = 21,
}

export enum MeasureType{
    Null = "Null",
    Other = "OTHER",

    Person = "PERSON",
    CommentManager = "COMMENTARY_MANAGER",
    CommentExecutior = "COMMENTARY_EXECUTIONNER",
    CommentFireman = "COMMENTARY_SECUTIRY",
    CommentElectrician = "COMMENTARY_ELECTRICIAN",
    CommentReceptionist = "COMMENTARY_RECEPTIONNIST",

    Oxygen = "O2",
    ExplosiveGas = "EXPLO",
    DioxydeCarbon = "CO2",
    HydrogenSulfide = "H2S",
}

export const getMeasureTypeLabel = (type: MeasureType): string => {
    switch(type){
        case MeasureType.Oxygen:
            return "Oxygène";
        case MeasureType.ExplosiveGas:
            return "Gaz explosif";
        case MeasureType.DioxydeCarbon:
            return "Dioxyde de carbone";
        case MeasureType.HydrogenSulfide:
            return "Hydrogène sulfuré";
        default:
            return "";
    }
}

export const getTimeOfDate = (date: string): string => {
    const dateObj = new Date(date);
    return withTwoDigits(dateObj.getHours()) + ":" + withTwoDigits(dateObj.getMinutes());
}

export const getDateOnly = (date: string): string => {
    const dateObj = new Date(date);
    return withTwoDigits(dateObj.getDate()) + "/" + withTwoDigits(dateObj.getMonth() + 1) + "/" + dateObj.getFullYear();
}

const withTwoDigits = (value: number): string => {
    return value < 10 ? "0" + value : value.toString();
}

export const getCommentByUserRole = (role: Role): MeasureType => {
    switch(role){
        case Role.Fireman:
            return MeasureType.CommentFireman;
        case Role.Manager:
            return MeasureType.CommentManager;
        case Role.Electrician:
            return MeasureType.CommentElectrician;
        case Role.Receptionnists:
            return MeasureType.CommentReceptionist;
        case Role.Executor:
            return MeasureType.CommentExecutior;
        default:
            throw new Error("getCommentByUserRole not supported for role " + role);
    }
}