// ARG ROUTE
export const ID_ROUTE = ':idOrQrCodeValue';
export const QRCODE_ROUTE = ':qrCodeValue';
export const WORK_ORDER_ID_ROUTE = ':workOrderId';

export const IS_CREATOR_ARG_ROUTE = '?isCreator=';
export const WORK_ORDER_ARG_ROUTE = '&workOrderId=';
export const SIGN_TYPE_ARG_ROUTE = '&signType=';
export const SIGN_TYPE_FIRST_ARG_ROUTE = '?signType=';
// ROUTES
export const ROOT_ROUTE = '/';
export const WORK_ORDER_ROUTE = '/work-orders';
export const UNIQUE_WORK_ORDER_ROUTE = '/work-orders/' + ID_ROUTE;
export const SIGNATURE_STATE_ROUTE = '/signature/state/' + QRCODE_ROUTE;
export const SCAN_WORK_ORDER_ROUTE = '/work-orders/scan';
export const ATTEMPT_CONNECT_ROUTE = '/attempt-connect/';
export const ONE_PHONE_SIGNATURE_ROUTE = '/signature/one-phone/' + QRCODE_ROUTE;

export const getRouteName = (route: string): string => {
    return route.replace("?","").replace("=","").replace("&","");
}

export const tryGetValueOfQrCode = (urlOrQrCodeValue: string): string => {
    if(urlOrQrCodeValue.includes(ATTEMPT_CONNECT_ROUTE)){
        return urlOrQrCodeValue.split(ATTEMPT_CONNECT_ROUTE)[1];
    }else{
        return urlOrQrCodeValue;
    }
}