export type ConsultTableElement = {
    titleTable: string,
    titleBackgroundCss: string,
    headers: ConsultTableHeader[],
}

export type ConsultTableHeader = {
    icon: ConsultTableHeaderIcon,
    title: string,
}

export enum ConsultTableHeaderIcon{
    DATE = 1,
    PERSON = 2,
    MEASURE = 3,
}