import React, { useEffect, useState } from "react";
import './SignatureState.css';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AppTools, MessageViewType, Role, SignState, SignatureType } from "../../types.ts";
import { ID_ROUTE, IS_CREATOR_ARG_ROUTE, QRCODE_ROUTE, SIGNATURE_STATE_ROUTE, SIGN_TYPE_ARG_ROUTE, UNIQUE_WORK_ORDER_ROUTE, WORK_ORDER_ARG_ROUTE, WORK_ORDER_ROUTE, getRouteName } from "../../route.ts";
import QRCode from "react-qr-code";
import RoleState from "../../views/signature/RoleState.tsx";
import { useRequest } from "../../hooks/index.js";
import { BASE_URL } from "../../const.ts";
import { ApiError, SignatureStateResponse } from "../../api/responses.ts";

type SignatureStateProps = {
    appTools: AppTools
}

const SignatureState = (props: SignatureStateProps) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { qrCodeValue } = useParams();
    const isCreator:boolean = searchParams.get(getRouteName(IS_CREATOR_ARG_ROUTE)) == "true";
    const workOrderId: string | null = searchParams.get(getRouteName(WORK_ORDER_ARG_ROUTE));
    const signType: string | null = searchParams.get(getRouteName(SIGN_TYPE_ARG_ROUTE));

    const [postWithResponse, post, get, deleteR] = useRequest(BASE_URL, props.appTools);

    const [signStates, setSignStates] = useState<SignState[]>([]);
    const [nbGetState, setNbGetState] = useState<number>(0);
    const [isFinished, setIsFinished] = useState<boolean>(false);

    useEffect(() => {
        if(workOrderId == null || signType == null){
            navigate(WORK_ORDER_ROUTE);
        }else{
            get<SignatureStateResponse>("/signature/state?workIdConcerned=" + workOrderId + "&signatureType=" + signType).then(response => {
                let results: SignState[] = [];
                response.data.roleNeeded.forEach(role => {
                    results.push({role:role, isSigned: response.data.alreadySigned.includes(role)});
                });
                
                setSignStates(results);
                setNbGetState(1);
            }).catch((err) => {
                navigate(WORK_ORDER_ROUTE);
            })
        }
    }, []);

    useEffect(() => {
        if(nbGetState != 0){
            setTimeout(() => {
                get<SignatureStateResponse>("/signature/state?workIdConcerned=" + workOrderId + "&signatureType=" + signType).then(response => {
                    if(response.data.isFinished){
                        setIsFinished(true);
                        const newStates = signStates;
                        for (let index = 0; index < newStates.length; index++) {
                            newStates[index].isSigned = true;
                        }
                        setSignStates(newStates);
                    }else{
                        const newStates = signStates;
                        for (let index = 0; index < newStates.length; index++) {
                            if(newStates[index].isSigned){
                                continue;
                            }
                            if(response.data.alreadySigned.includes(newStates[index].role)){
                                newStates[index].isSigned = true;
                            }
                        }
                        setSignStates(newStates);

                        setNbGetState(nbGetState+1);
                    }
                }).catch((err) => {
                    // on vérifie que l'utilisateur n'a pas été redirigé vers une autre page
                    // si c'est le cas, on ne fait rien
                    if(window.location.href.includes(SIGNATURE_STATE_ROUTE.replace(QRCODE_ROUTE,""))){
                        if(workOrderId){
                            navigate(UNIQUE_WORK_ORDER_ROUTE.replace(ID_ROUTE, workOrderId));
                        }else{
                            navigate(WORK_ORDER_ROUTE);
                        }
                    }
                })
            },3000);
        }
    }, [nbGetState]);

    useEffect(() => {
        if(isFinished){
            setTimeout(() => {
                let message: string = "";
                switch(signType){
                    case SignatureType.HALF_DAY_MORNING:
                        message = "Début demi-journée matin signé";
                        break;
                    case SignatureType.HALF_DAY_AFTERNOON:
                        message = "Début demi-journée après-midi signé";
                        break;
                    case SignatureType.END_HALF_DAY_MORNING:
                        message = "Fin demi-journée matin signé";
                        break;
                    case SignatureType.END_HALF_DAY_AFTERNOON:
                        message = "Fin demi-journée après-midi signé";
                        break;
                    case SignatureType.WORKS_ACCEPTANCE:
                        message = "Réception des travaux signé";
                        break;
                    case SignatureType.OPTIONNAL:
                        message = "Signature optionnel signé";
                        break;
                }
                props.appTools.showMessage(MessageViewType.Success, message);
                navigate(WORK_ORDER_ROUTE);
            }, 3000);
        }
    }, [isFinished])

    const triggerCancel = () => {
        props.appTools.isLoading(true);
        deleteR("/signature/" + workOrderId).then(() => {
        }).catch((err) => {
            const apiErr = err as ApiError;
            if(apiErr.code == 404){
                props.appTools.showMessage(MessageViewType.NotFound, apiErr.message,
                    "Il se peut que le cycle de signature ait été finalisé avant que "+
                    "la demande de suppression nous parviennes."
                )
            }else{
                props.appTools.showMessage(MessageViewType.Error, apiErr.message)
            }
        }).finally(() => {
            props.appTools.isLoading(false);
            if(workOrderId){
                navigate(UNIQUE_WORK_ORDER_ROUTE.replace(ID_ROUTE, workOrderId));
            }else{
                navigate(WORK_ORDER_ROUTE);
            }
        })
    }

    return (
        <div className="signstate-main fill-to-screen">
            <div className="signstate-container">
                <div className="signstate-qrcode-container">
                    <QRCode value={qrCodeValue!} size={193} />
                </div>

                {workOrderId && (
                    <p className="signstate-workorder-num">QR Code du permis n°{workOrderId}</p>
                )}

                <div className="signstate-separator" />
                
                {signStates && signStates.map((model, index) => {
                    return (
                        <RoleState key={index} role={model.role} isSigned={model.isSigned} />
                    );
                })}

                <div className="signstate-bottom-container">
                    {!isFinished && (
                        <p className="signstate-txt">En attente des signatures restantes</p>
                    )}
                    {isCreator && !isFinished && (
                        <div onClick={() => {triggerCancel()}} className="petra-button signstate-cancel">
                            Annuler
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
};

export default SignatureState;