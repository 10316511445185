import { ReactNode, useState } from "react";

const usePlaceholder = () => {
    const [listDisplay, setListDisplay] = useState<ReactNode[]>([]);

    const pushDisplay = (display: ReactNode) => {
        setListDisplay(prev => [...prev, display]);
    }

    const popDisplay = () => {
        setListDisplay(prev => {
            const newListDisplay = [...prev];
            newListDisplay.pop();
            return newListDisplay;
        });
    }

    const clearDisplay = () => {
        setListDisplay([]);
    }

    return [listDisplay, pushDisplay, popDisplay, clearDisplay] as const;
};

export default usePlaceholder;