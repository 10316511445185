import React from 'react';
import './LoadingView.css';

const LoadingView = () => {
    return (
        <div className="loadingio-spinner-pulse-k6ecr35clk">
            <div className="ldio-y8vj8sgdk28">
                <div></div><div></div><div></div>
            </div>
        </div>
    );
}

export default LoadingView;