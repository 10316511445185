import React, {useEffect, useState} from "react";
import './Login.css';
import { PetraSignLogoImg, QrCodeImg } from "../../images";
import { QRCodeReader } from "../../views";
import { AppTools } from "../../types.ts";
import { ATTEMPT_CONNECT_ROUTE, tryGetValueOfQrCode } from "../../route.ts";
import config from "../../config.ts";

type LoginProps = {
    isExistUserId: (userId: string) => Promise<boolean>,
    appTools: AppTools
}

const Login = (props : LoginProps) => {

    const { isExistUserId, appTools } = props;

    const [readQRCode, setReadQRCode] = useState<boolean>(false);

    const onQRCodeRead = (text: string) => {
        text = tryGetValueOfQrCode(text);
        setReadQRCode(false);
        appTools.isLoading(true);
        isExistUserId(text)
        .then((result) => {
            // il n'y a rien à faire, la fonction 'isExistUserId'
            // va changer l'état de connexion et App.tsx se charge de 
            // changer de page 
        })
        .catch((error) => {

        })
        .finally(() => {
            appTools.isLoading(false);
        })
    };

    useEffect(() => {
        // vérifie si l'url contient un 'attempt-connect', cela signifie
        // que l'utilisateur à scanner son QRCode utilisateur
        if(window.location.href.includes(ATTEMPT_CONNECT_ROUTE)){
            // on récupère la valeur en fin de route après 'attempt-connect'
            onQRCodeRead(window.location.href);
        }
    }, [])

    return (
        <>
            {!readQRCode && (
                <div className="login-main fill-to-screen">
                    <img src={PetraSignLogoImg} className="login-title" />
                    <p className="login-description">Pour vous authentifier, flasher le QR Code associé à votre profil.</p>
                    <img className="login-img" src={QrCodeImg} />
                    <button onClick={() => {setReadQRCode(true)}} className="white-button login-bottom-button">Scanner</button>
                </div>
            )}
            {readQRCode && (
                <QRCodeReader 
                    valueToIncludeToValidateQrCode={config.appNameDomain}
                    description="Flasher le QR Code associé à votre profil" 
                    handleQrCodeRead={onQRCodeRead}
                    onGoBack={() => setReadQRCode(false)} />
            )}
        </>
    );
}

export default Login;