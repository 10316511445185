import axios from 'axios';
import Cookies from 'universal-cookie';
import { COOKIES_TOKEN, ERR_API_CODE_EXPIRED_TOKEN, ERR_API_CODE_INVALID_TOKEN, X_PAGINATION } from '../const.ts';
import { ApiError, ApiReponse, Pagination } from '../api/responses.ts';
import { AppTools } from '../types.ts';

const cookies = new Cookies(null, { path: '/' });

const defaultErr: ApiError = {
    code: 500,
    errorCode: -1,
    status: "InternalError",
    message: "Unkhown Error"
}

const tryGetErrorOrDefault = (err: any) : ApiError => {
    if(err.response && err.response.data){
        const errApi = err.response.data as ApiError;
        if(errApi != undefined){
            return errApi;
        }
    }

    return defaultErr;
}

const useRequest = (baseUrl: string, appTools?: AppTools, customToken?: string) => {

    const checkIfErrIsInvalidToken = (err: ApiError) => {
        if((err.errorCode === ERR_API_CODE_INVALID_TOKEN || 
            err.errorCode === ERR_API_CODE_EXPIRED_TOKEN)
            && appTools){
            appTools.checkUserConnected();
        }
    }

    const getToken = (forceCustomToken?: string) => {
        if(forceCustomToken){
            return forceCustomToken;
        }
        if(customToken){
            return customToken;
        }else{
            return cookies.get(COOKIES_TOKEN);
        }
    }

    const postWithResponse = <TResult,>(url: string, body?: any): Promise<TResult> =>  {
        return new Promise<TResult>((resolve,reject) => {
            axios.post(baseUrl + url, body, {
                headers:{
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'x-token': getToken(),
                    'ngrok-skip-browser-warning': true
                }
            }).then((res) => {
                resolve(res.data as TResult);
                appTools?.reloadTimerExpiration();
            }).catch((err) => {
                const apiErr = tryGetErrorOrDefault(err);
                checkIfErrIsInvalidToken(apiErr);
                reject(apiErr);
            })
        });
    }

    const post = (url: string, body?: any): Promise<void> => {
        return new Promise<void>((resolve,reject) => {
            axios.post(baseUrl + url, body, {
                headers:{
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'x-token': getToken(),
                    'ngrok-skip-browser-warning': true
                }
            }).then((res) => {
                resolve();
                appTools?.reloadTimerExpiration();
            }).catch((err) => {
                const apiErr = tryGetErrorOrDefault(err);
                checkIfErrIsInvalidToken(apiErr);
                reject(apiErr);
            })
        });
    }

    const get = <TResult,>(url: string, pagination? : Pagination, forceCustomToken?: string): Promise<ApiReponse<TResult>> =>  {
        return new Promise<ApiReponse<TResult>>((resolve,reject) => {
            axios.get(baseUrl + url,{
                headers:{
                    'Accept': 'application/json',
                    'x-token': getToken(forceCustomToken),
                    'x-pagination': pagination ? JSON.stringify(pagination) : '',
                    'ngrok-skip-browser-warning': true
                }
            }).then((res) => {
                const apiReponse: ApiReponse<TResult> = {
                    data: res.data as TResult,
                }
                if(res.headers.get && res.headers.get(X_PAGINATION)){
                    const pagination = JSON.parse(res.headers.get(X_PAGINATION)) as Pagination;
                    apiReponse.pagination = pagination;
                }

                resolve(apiReponse);
                appTools?.reloadTimerExpiration();
            }).catch((err) => {
                const apiErr = tryGetErrorOrDefault(err);
                checkIfErrIsInvalidToken(apiErr);
                reject(apiErr);
            })
        });
    }

    const deleteR = (url: string): Promise<void> => {
        return new Promise((resolve, reject) => {
            axios.delete(baseUrl + url, {
                headers:{
                    'Accept': 'application/json',
                    'x-token': getToken(),
                    'ngrok-skip-browser-warning': true
                }
            }).then(() => {
                resolve();
            }).catch((err) => {
                const apiErr = tryGetErrorOrDefault(err);
                checkIfErrIsInvalidToken(apiErr);
                reject(apiErr);
            })
        });
    }

    const getFilePdf = (url: string): Promise<string> => {
        return new Promise<string>((resolve, reject) => {
            axios.get(baseUrl + url, {
                headers:{
                    'x-token': getToken(),
                    'ngrok-skip-browser-warning': true,
                    'responseType': 'blob'
                }
            }).then((response) => {
                const reader = new FileReader();
                reader.readAsDataURL(new Blob([response.data], {type: 'application/pdf'}));
                reader.onloadend = () => {
                    const base64 = reader.result as string;
                    resolve(base64);
                }
            }).catch((err) => {
                const apiErr = tryGetErrorOrDefault(err);
                checkIfErrIsInvalidToken(apiErr);
                reject(apiErr);
            })
        });
    };

    return [postWithResponse, post, get, deleteR, getFilePdf] as const;
}

export default useRequest;