import React, { createRef, useState } from "react";
import './SelectInput.css';

export type SelectInputProps = {
    title: string,
    isNumber: boolean,
    placeholder?: string,
    onValidate: (value: any) => void,
    onCancel: () => void,
    /**
     * Si vrai, créer un `textArea` au lieu d'un `input`
     * 
     * ⚠️ **Incompatible avec `isNumber`**
     */
    biggerInput?: boolean
}

const SelectInput = (props: SelectInputProps) => {
    const {title, isNumber, placeholder, onValidate, onCancel, biggerInput = false } = props;
    const typeInput = isNumber ? "number" : "text";
    const [value, setValue] = useState<any>("");

    const mainRef = createRef<HTMLDivElement>();
    const wrapperRef = createRef<HTMLDivElement>();

    const triggerOnClose = (value?: any) => {
        if(mainRef.current && wrapperRef.current){
            mainRef.current!.style.animationName = "fadeOut";
            wrapperRef.current!.style.animationName = "UpToBottom";
            mainRef.current!.scrollTo({top: 0, behavior: "smooth"});
            setTimeout(() => {
                if(mainRef.current){
                    mainRef.current!.style.opacity = "0";
                }
                if(value){
                    onValidate(value);
                }else{
                    onCancel();
                }
            },400);
        }else{
            if(value){
                onValidate(value);
            }else{
                onCancel();
            }
        }
    }

    return (
        <div ref={mainRef} className="select-input-main">
            <div className="select-input-empty-space" onClick={() => triggerOnClose()}></div>
            <div ref={wrapperRef} className="select-input-wrapper">
                <div className="select-input-container">
                    <div className="select-input-round-square" />
                    <p className="select-input-txt">{title}</p>

                    {biggerInput ? (
                        <textarea className="select-input-input select-input-big-input" placeholder={placeholder} value={value} onChange={(e) => {setValue(e.target.value)}} />
                    ): (
                        <input className="select-input-input" type={typeInput} placeholder={placeholder} value={value} onChange={(e) => {setValue(e.target.value)}} />
                    )}
                        
                    <div className="select-input-button select-input-button-valid" onClick={() => {triggerOnClose(value)}}>
                        <p>Valider</p>
                    </div>

                    <div className="select-input-button select-input-button-cancel" onClick={() => {triggerOnClose(null)}}>
                        <p>Annuler</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SelectInput;