import React, { createRef, useEffect, useState } from 'react';
import './MessageView.css';

import { NotFoundWhiteImg, CheckWhiteImg, CrossWhiteImg, WarningWhiteImg } from '../../images/index.js';
import { MessageViewType } from '../../types.ts';

export type MessageViewProps = {
    type: MessageViewType,
    text: string,
    description?: string,
    hideMessage: () => void
}

const MessageView = (props : MessageViewProps) => {
    const {type, text, description, hideMessage} = props;
    const mainRef = createRef<HTMLDivElement>();
    const [sourceImg, setSourceImg] = useState<string>("");

    const triggerHideMessage = () => {
        mainRef.current!.style.animationName = "slideout";
        setTimeout(() => {
            if(mainRef.current){
                mainRef.current!.style.opacity = "0";
            }
            hideMessage();
        },400);
    }

    useEffect(() => {
        if(mainRef.current != null){
            switch(type){
                case MessageViewType.Success:
                    mainRef.current.style.backgroundColor = "#4CB050";
                    setSourceImg(CheckWhiteImg);
                    break;
                case MessageViewType.Error:
                    mainRef.current.style.backgroundColor = "#FF5253";
                    setSourceImg(CrossWhiteImg);
                    break;
                case MessageViewType.Warning:
                    mainRef.current.style.backgroundColor = "#FD9700";
                    setSourceImg(WarningWhiteImg);
                    break;
                case MessageViewType.NotFound:
                    mainRef.current.style.backgroundColor = "#FD9700";
                    setSourceImg(NotFoundWhiteImg);
                    break;
            }
        }
    }, [mainRef]);
    
    return (
        <div ref={mainRef} className='message-main'>
            <div className='message-container'>
                <img className='message-icon' src={sourceImg} />
                
                <p className='message-text'>{text}</p>
                <p className='message-description'>{description}</p>
            </div>
            
            <button className='message-button' onClick={() => {triggerHideMessage()}} >OK</button>
        </div>
    );
}

export default MessageView;