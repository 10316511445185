import React, { createRef, useEffect, useState } from 'react';
import './WorkOrders.css';
import { GoBack, SearchBar, WOElement } from '../views';
import { useNavigate } from 'react-router-dom';
import { ROOT_ROUTE } from '../route.ts';
import {useRequest} from '../hooks';
import { BASE_URL } from '../const.ts';
import { AppTools, MessageViewType, Role, WorkOrderState } from '../types.ts';
import { UserInfoResponse, WorkOrderResponse } from '../api/responses.ts';

type WorkOrdersProps = {
    appTools: AppTools
}

const WorkOrders = (props: WorkOrdersProps) => {
    const {appTools} = props;
    const navigate = useNavigate();
    const [, , get] = useRequest(BASE_URL, props.appTools);

    const [workOrders, setWorkOrders] = useState<WorkOrderResponse[] | undefined>();
    const [workOrdersNotStarted, setWorkOrdersNotStarted] = useState<WorkOrderResponse[] | undefined>();
    const [workOrdersStarted, setWorkOrdersStarted] = useState<WorkOrderResponse[] | undefined>();
    const [workOrderFinished, setWorkOrderFinished] = useState<WorkOrderResponse[] | undefined>();

    const [userRole, setUserRole] = useState<Role| undefined>();
    const [hasNext, setHasNext] = useState<boolean>(false);
    const [currentPagination, setCurrentPagination] = useState<number>(1);

    //#region Search State

    const [isSearching, setIsSearching] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>("");
    const [searchResults, setSearchResults] = useState<WorkOrderResponse[] | undefined>();
    const [searchHaveNext, setSearchHaveNext] = useState<boolean>(false);
    const [searchCurrentPagination, setSearchCurrentPagination] = useState<number>(1);

    //#endregion

    const getUserRole = (roles: Role[])=> {
        roles = roles.filter(x => x !== Role.Admin);
        if(roles.length == 1){
            setUserRole(roles[0])
        }
        else if(roles.length > 1){
            appTools.displayRoleSelector(roles).then((role) => {
                setUserRole(role);
            }).catch(() => {
                appTools.showMessage(MessageViewType.Error, "Veuillez selectionner un rôle pour continuer");
            });
        }else{
            appTools.showMessage(MessageViewType.Error, "Vous n'avez pas de rôle attribué");
        }
    }

    useEffect(() => {
        get<UserInfoResponse>("/user/getCurrentInfo").then((response) => {
            getUserRole(response.data.userRoleIds);
        }).catch(() => {});

        get<WorkOrderResponse[]>("/workOrders/getWorksConcerned").then((response) => {
            setWorkOrders(response.data);
            if(response.pagination && response.pagination.HasNext != undefined){
                setHasNext(response.pagination.HasNext);
            }
        }).catch(() => {});
    }, []);

    useEffect(() => {
        if(currentPagination != 1){
            get<WorkOrderResponse[]>("/workOrders/getWorksConcerned", {CurrentPage: currentPagination}).then((response) => {
                setWorkOrders([...workOrders!, ...response.data]);
                if(response.pagination && response.pagination.HasNext != undefined){
                    setHasNext(response.pagination.HasNext);
                }else{
                    setHasNext(false);
                }
            }).catch(() => {});
        }
    }, [currentPagination]);

    useEffect(() => {
        if(workOrders){
            setWorkOrdersStarted(workOrders.filter(x => x.workState != WorkOrderState.AwaitingDecision && x.workState != WorkOrderState.WorksApproved));
            setWorkOrdersNotStarted(workOrders.filter(x => x.workState == WorkOrderState.AwaitingDecision));
            setWorkOrderFinished(workOrders.filter(x => x.workState == WorkOrderState.WorksApproved));
        }
    }, [workOrders])

    //#region Search Functions

    const search = (searchValue: string) => {
        setIsSearching(true);
        setSearchValue(searchValue);
        setSearchHaveNext(false);
        setSearchCurrentPagination(1);

        getSearchResults(searchValue, 1);
    }

    const searchMore = () => {
        getSearchResults(searchValue, searchCurrentPagination + 1);
    }

    const getSearchResults = (searchValue: string, currentPagination: number) => {
        get<WorkOrderResponse[]>("/workOrders/search/" + searchValue, {CurrentPage: currentPagination}).then((response) => {
            if(searchResults && currentPagination != 1){
                setSearchResults([...searchResults, ...response.data]);
            }else{
                setSearchResults(response.data);
            }
            if(response.pagination && response.pagination.HasNext != undefined){
                setSearchHaveNext(response.pagination.HasNext);
                setSearchCurrentPagination(response.pagination.CurrentPage);
            }else{
                setSearchHaveNext(false);
            }
        }).catch(() => {});
    }

    const cancelSearch = () => {
        setIsSearching(false);
        setSearchValue("");
        setSearchResults(undefined);
        setSearchCurrentPagination(1);
    }

    //#endregion

    return (
        <div className='worders-main fill-to-screen' >
            <div className='wordres-bg fill-to-screen' />
            <GoBack onGoBack={() => {navigate(ROOT_ROUTE)}} />

            <div className='worders-wrapper'>
                <div className='worders-container'>
                    <SearchBar 
                        onSearch={search}
                        onCancel={cancelSearch} />

                    {/* Affichage en mode recherche */}
                    {isSearching && userRole && searchResults && searchResults.length != 0 && (
                        <>
                            <h1 className='worders-section'>Résultats recherche</h1>
                            <div className='worders-separator' />

                            {searchResults.map((wo, index) => {
                                return (
                                    <WOElement key={-index} userRole={userRole} workOrder={wo} />
                                );
                            })}

                            {searchHaveNext && (
                                <div className='white-button worders-more-button' onClick={searchMore}>
                                    <p>Charger plus de bon</p>
                                </div>
                            )}
                        </>
                    )}

                    {!isSearching && userRole && workOrdersStarted && workOrdersStarted.length == 0 && workOrdersNotStarted && workOrdersNotStarted.length == 0 && (
                        <p className='worders-txt'>Aucun bon</p>
                    )}

                    {/* Affichage de la page par défaut */}
                    {!isSearching && userRole && workOrdersStarted && workOrdersStarted.length != 0 && (
                        <>
                            <h1 className='worders-section'>En cours</h1>
                            <div className='worders-separator' />
                        </>
                    )}
                    {!isSearching && userRole && workOrdersStarted && workOrdersStarted.map(wo => {
                        return (
                            <WOElement key={wo.workId} userRole={userRole} workOrder={wo} />
                        );
                    })}

                    {!isSearching && userRole && workOrdersNotStarted && workOrdersNotStarted.length != 0 && (
                        <>
                            <h1 className='worders-section'>Bon concernés</h1>
                            <div className='worders-separator' />
                        </>
                    )}
                    {!isSearching && userRole && workOrdersNotStarted && workOrdersNotStarted.map(wo => {
                        return (
                            <WOElement key={wo.workId} userRole={userRole} workOrder={wo} />
                        );
                    })}

                    {!isSearching && userRole && workOrderFinished && workOrderFinished.length != 0 && (
                        <>
                            <h1 className='worders-section'>Bon terminés</h1>
                            <div className='worders-separator' />
                        </>
                    )}
                    {!isSearching && userRole && workOrderFinished && workOrderFinished.map(wo => {
                        return (
                            <WOElement key={wo.workId} userRole={userRole} workOrder={wo} />
                        );
                    })}
                    {!isSearching && userRole && hasNext && (
                        <div className='white-button worders-more-button' onClick={() => setCurrentPagination(currentPagination + 1)}>
                            <p>Charger plus de bon</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default WorkOrders;