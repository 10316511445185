import React, { createRef, useEffect, useState } from 'react';
import './DeclareSignature.css'
import { MultiplePhoneImg, OnePhoneImg } from '../../images';
import { AppTools, getCommentByUserRole, MeasureType, MessageViewType, Role, SignatureTypeDeclaration } from '../../types.ts';
import { useNavigate } from 'react-router-dom';
import { DeclareOptionalSignModel, DeclareSignModel, MeasureModel } from '../../api/model.ts'
import { useRequest } from '../../hooks/index.js';
import { BASE_URL } from '../../const.ts';
import { DeclareSignResponse, ApiError } from '../../api/responses.ts';
import { IS_CREATOR_ARG_ROUTE, ONE_PHONE_SIGNATURE_ROUTE, QRCODE_ROUTE, SIGNATURE_STATE_ROUTE, SIGN_TYPE_ARG_ROUTE, SIGN_TYPE_FIRST_ARG_ROUTE, WORK_ORDER_ARG_ROUTE, WORK_ORDER_ID_ROUTE } from '../../route.ts';
import { CommentaryView, MeasureView, NbWorkerView } from '../index.js';

type DeclareSignatureProps = {
    appTools: AppTools,
    onClose: () => void,
    mainWorkOrderId: number,
    mainWorkOrderLabelle: string,
    userRoles: Role[],
    declarationType : SignatureTypeDeclaration,
    workOrderChildrens?: {id: number, labelle: string}[],
    customToken?: string
}

const DeclareSignature = (props: DeclareSignatureProps) => {
    const {appTools, onClose, mainWorkOrderId, mainWorkOrderLabelle, userRoles, declarationType, workOrderChildrens, customToken} = props;
    const navigate = useNavigate();
    const [postWithResponse] = useRequest(BASE_URL, props.appTools, customToken);
    const allWorkOrder: {id: number, labelle: string}[] = [{id: mainWorkOrderId, labelle: mainWorkOrderLabelle}, ...(workOrderChildrens ? workOrderChildrens! : [])];
    const [measures, setMeasures] = useState<MeasureModel[]>([]);
    const [currentRole, setCurrentRole] = useState<Role | undefined>();
    const [nbWorker, setNbWorker] = useState<number | undefined>();
    const [commentary, setCommentary] = useState<string | undefined>();

    const mainRef = createRef<HTMLDivElement>();
    const wrapperRef = createRef<HTMLDivElement>();

    const [annexeCheckBoxRefs, setAnnexeCheckBoxRefs] = useState<React.RefObject<HTMLInputElement>[]>([]);

    const triggerOnClose = (errorMessage?: string) => {
        if(errorMessage){
            appTools.showMessage(MessageViewType.Warning, errorMessage);
        }

        if(mainRef.current && wrapperRef.current){
            mainRef.current!.style.animationName = "fadeOut";
            wrapperRef.current!.style.animationName = "UpToBottom";
            mainRef.current!.scrollTo({top: 0, behavior: "smooth"});
            setTimeout(() => {
                if(mainRef.current){
                    mainRef.current!.style.opacity = "0";
                }
                onClose();
            },400);
        }else{
            onClose();
        }
    }

    const getUserRole = (): Promise<Role> => {
        return new Promise((resolve, reject) => {
            let roles;
            if(declarationType !== SignatureTypeDeclaration.WORK_ACCEPTANCE){
                roles = userRoles.filter(x => x !== Role.Admin && x !== Role.Receptionnists);
            }else{
                roles = userRoles.filter(x => x !== Role.Admin);
            }

            if(roles.length == 1){
                resolve(roles[0]);
            }
            else if(roles.length > 1){
                appTools.displayRoleSelector(roles).then((role) => {
                    resolve(role);
                }).catch(() => {
                    triggerOnClose("Veuillez selectionner un rôle pour signer");
                    reject();
                });
            }else{
                triggerOnClose("Impossible de signer");
                reject();
            }
        });
    }

    //#region triggerOneDevice & triggerMultipleDevices

    const triggerOneDevice = () => {
        appTools.isLoading(true);
        declareSign().then((response) => {
            navigate(
                ONE_PHONE_SIGNATURE_ROUTE.replace(QRCODE_ROUTE, response.qrCodeValue)
                + SIGN_TYPE_FIRST_ARG_ROUTE + response.signatureType
            );
        }).catch(() => {}).finally(() => {
            appTools.isLoading(false);
            triggerOnClose();
        });
    }

    const triggerMultipleDevices = () => {
        appTools.isLoading(true);
        declareSign().then((response) => {
            navigate(
                SIGNATURE_STATE_ROUTE.replace(QRCODE_ROUTE, response.qrCodeValue) 
                + IS_CREATOR_ARG_ROUTE + "true"
                + WORK_ORDER_ARG_ROUTE + mainWorkOrderId.toString() 
                + SIGN_TYPE_ARG_ROUTE + response.signatureType
                );
        }).catch(() => {}).finally(() => {
            appTools.isLoading(false);
            triggerOnClose();
        });
    }

    //#endregion

    //#region declareSign

    const declareSign = (): Promise<DeclareSignResponse> => {
        // récupérer les id des bon de travaux annexes cochés
        const workOrderChildrensChecked:number[] = [];
        for (let index = 0; index < annexeCheckBoxRefs.length; index++) {
            const ref = annexeCheckBoxRefs[index];
            if(ref.current!.checked){
                workOrderChildrensChecked.push(workOrderChildrens![index].id);
            }
        }

        if(declarationType != SignatureTypeDeclaration.OPTIONNAL){
            return declareNormalSignature(currentRole!, workOrderChildrensChecked);
        }else{
            return declareOptionalSignature(currentRole!, workOrderChildrensChecked);
        }
    }

    const declareNormalSignature = (currentRole : Role, workOrderChildrensChecked:number[]): Promise<DeclareSignResponse> => {
        return new Promise<DeclareSignResponse>(async (resolve,reject) => {
            const measuresToSend: MeasureModel[] = [...measures];
            // si le manager a renseigné le nombre de travailleur, on l'ajoute dans les mesures
            if(nbWorker){
                measuresToSend.push({
                    workIdConcerned: mainWorkOrderId,
                    measureType: MeasureType.Person,
                    measureValue: nbWorker.toString()
                });
            }
            // si un commentaire a été renseigné, on l'ajoute dans les mesures
            if(commentary){
                measuresToSend.push({
                    workIdConcerned: mainWorkOrderId,
                    measureType: getCommentByUserRole(await getUserRole()),
                    measureValue: commentary
                });
            }
            
            const declareModel: DeclareSignModel = {
                roleIdUser:  currentRole,
                workIdConcerned: mainWorkOrderId,
                workIdChildrens: workOrderChildrensChecked,
                signType: declarationType,
                measures: measuresToSend
            };

            postWithResponse<DeclareSignResponse>("/signature/declare", declareModel).then(response => {
                resolve(response);
            }).catch((err) => {
                const apiErr = err as ApiError;
                if(apiErr != undefined){
                    appTools.showMessage(MessageViewType.Error, apiErr.message);
                }else{
                    appTools.showMessage(MessageViewType.Error, "Une erreur est survenu");
                }
                reject();
            })
        }) 
    }

    const declareOptionalSignature = (currentRole : Role, workOrderChildrensChecked:number[]): Promise<DeclareSignResponse> => {
        return new Promise<DeclareSignResponse>((resolve,reject) => {
            if(workOrderChildrensChecked.length == 0){
                appTools.showMessage(MessageViewType.Warning, "Aucune annexe sélectionnée");
                reject();
                return;
            }
            const declareModel: DeclareOptionalSignModel = {
                roleIdUser:  currentRole,
                workIdConcerned: mainWorkOrderId,
                workIdChildrens: workOrderChildrensChecked,
                measures: measures
            }
            postWithResponse<DeclareSignResponse>("/signature/declareOptionnal", declareModel).then(response => {
                resolve(response);
            }).catch((err) => {
                const apiErr = err as ApiError;
                if(apiErr != undefined){
                    appTools.showMessage(MessageViewType.Error, apiErr.message);
                }else{
                    appTools.showMessage(MessageViewType.Error, "Une erreur est survenu");
                }
                reject();
            })
        }) 
    }

    //#endregion

    // on prépare les références des checkbox afin de savoir 
    // quelles annexes sont cochés
    useEffect(() => {
        let getRole = true;

        // il faut proposer les annexes uniquement si on déclare une demi-journée
        // déclarer une fin de demi journée ne nécessite pas de signer les annexes
        if(workOrderChildrens && declarationType == SignatureTypeDeclaration.START_HALF_DAY){
            setAnnexeCheckBoxRefs(workOrderChildrens.map(() => createRef()));
        }

        if(declarationType == SignatureTypeDeclaration.OPTIONNAL){
            if(workOrderChildrens && workOrderChildrens.length != 0){
                setAnnexeCheckBoxRefs(workOrderChildrens.map(() => createRef()));
            }else{
                // si on est en mode optionnel et qu'il n'y a pas d'annexe à signer
                // on s'arrête là, le but de la signature optionnel est de signer les annexes 
                // pas encore signées
                appTools.showMessage(MessageViewType.Warning, "Aucune annexe à signer");
                triggerOnClose();
                getRole = false;
            }
        }

        if(getRole){
            getUserRole().then((role) => {
                setCurrentRole(role);
            }).catch(() => {});
        }
    }, [])

    return (
        <div ref={mainRef} className='declsign-main fill-to-screen'>
            <div className='declsign-empty-space' onClick={() => triggerOnClose()} />

            <div ref={wrapperRef} className='declsign-wrapper'>
                <div className='declsign-container'>
                    <div className='declsign-round-square' />

                    {currentRole && workOrderChildrens && workOrderChildrens.length != 0 && annexeCheckBoxRefs.length != 0 && (
                        <>
                            {declarationType != SignatureTypeDeclaration.OPTIONNAL && (
                                <p className='declsign-txt'>Signer les annexes suivantes ?</p>
                            )}

                            {declarationType == SignatureTypeDeclaration.OPTIONNAL && (
                                <p className='declsign-txt'>Selectionner les annexes à signer</p>
                            )}

                            {workOrderChildrens.map((woc, index) => {
                                return (
                                    <div key={woc.id} className='declsign-checkbox-container'>
                                        <input ref={annexeCheckBoxRefs[index]} id={"decl_ckbox_" + woc.id} type='checkbox' value={woc.labelle} />
                                        <label htmlFor={"decl_ckbox_" + woc.id} >{woc.labelle}</label>
                                    </div>
                                );
                            })}

                            {declarationType == SignatureTypeDeclaration.OPTIONNAL && (
                                <p className='declsign-info'>
                                    Les annexes affichées sont uniquement les annexes 
                                    qui ne possèdent pas de signature
                                </p>
                            )}

                            <div className='declsign-separator' />
                        </>
                    )}

                    {(
                        declarationType == SignatureTypeDeclaration.START_HALF_DAY ||
                        declarationType == SignatureTypeDeclaration.OPTIONNAL
                    ) && 
                    ((currentRole === Role.Fireman && (
                        <>
                            {/* Les mesures peuvent être déclarer uniquement par les pompier */}
                            <MeasureView 
                                userRoleId={currentRole}
                                workOrders={allWorkOrder}
                                appTools={appTools}
                                measures={measures}
                                setMeasures={setMeasures}
                                isCenterTitle={true}
                                />
                            <div className='declsign-separator' />
                        </> 
                    )) || 
                    (currentRole === Role.Manager && (
                        <>
                            {/** Vue réservé à l'exploitant qui doit renseigner le nombre de 
                             * travailleur */}
                            <NbWorkerView nbWorker={nbWorker} setNbWorker={setNbWorker} appTools={appTools} />
                            <div style={{marginTop:"20px"}}/>
                            <div className='declsign-separator' />
                        </> 
                    )))}

                    <CommentaryView commentary={commentary} setCommentary={setCommentary} appTools={appTools} />
                    <div style={{marginTop:"20px"}}/>
                    <div className='declsign-separator' />

                    <p className='declsign-txt'>Combien d'appareil possédez vous ?</p>

                    <div className='declsign-container-buttons'>
                        <div className='declsign-big-button' onClick={() => {triggerOneDevice()}}>
                            <p>Un appareil</p>
                            <img src={OnePhoneImg} />
                        </div>
                        <div className='declsign-big-button' onClick={() => {triggerMultipleDevices()}}>
                            <p>Plusieurs appareils</p>
                            <img src={MultiplePhoneImg} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeclareSignature;