import { useEffect, useState } from "react";
import { AppTools } from "../types";
import { useRequest } from ".";
import { BASE_URL } from "../const.ts";
import { SignatureResponse } from "../api/responses";

const getAllGroupWithoutLast = (allGroupedSignatures: SignatureResponse[][]): SignatureResponse[][] => {
    // si le tableau est vide ou contient un seul groupe, on retourne un tableau vide
    if(allGroupedSignatures.length == 0 || allGroupedSignatures.length == 1){
        return [];
    }
    // sinon on retourne le tableau sans le dernier groupe
    return allGroupedSignatures.slice(0, allGroupedSignatures.length - 1);
}

const addSignatureGroup = (allGroupedSignatures: SignatureResponse[][], signatures: SignatureResponse[]): SignatureResponse[][] => {
    let newGroup: SignatureResponse[] = [];
    
    while(signatures.length != 0){
        // si le groupe est vide, on ajoute la signature
        if(newGroup.length == 0){
            newGroup.push(signatures.shift()!);
        }else{
            // si la signature appartient au groupe actuel, on l'ajoute
            if(signatures[0].signGroupId === newGroup[0].signGroupId){
                newGroup.push(signatures.shift()!);
            }else{
                // sinon on ajoute le groupe actuel dans les groupes
                allGroupedSignatures.push(newGroup);
                // on crée un nouveau groupe
                newGroup = [signatures.shift()!];
            }
        }
    }

    // on ajoute le dernier groupe s'il n'est pas vide
    if(newGroup.length != 0){
        allGroupedSignatures.push(newGroup);
    }

    return allGroupedSignatures;
}

const getNbSignGroup = (signatures: SignatureResponse[]): number => {
    let signGroup: string[] = [];
    signatures.forEach((signature) => {
        if(!signGroup.includes(signature.signGroupId)){
            signGroup.push(signature.signGroupId);
        }
    });
    return signGroup.length;

}

const useConsultSignature = (workOrderId: number, appTools: AppTools, customToken?: string) => {
    const [, , get] = useRequest(BASE_URL, appTools, customToken);
    const [pageNumberApi, setPageNumberApi] = useState<number>(0);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageNumberMax, setPageNumberMax] = useState<number>(1);
    const [currentSignatures, setCurrentSignatures] = useState<SignatureResponse[]>([]);
    const [allGroupedSignatures, setAllGroupedSignatures] = useState<SignatureResponse[][]>([]);
    const [hasNext, setHasNext] = useState<boolean>(true);
    const [isInit, setIsInit] = useState<boolean>(false);
    const [isFirstLoadingFinish, setIsFirstLoadingFinish] = useState<boolean>(false);

    const getSignature = (pageNumber: number): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            get<SignatureResponse[]>("/signature/getByWorkOrder/" + workOrderId, {CurrentPage: pageNumber})
            .then((response) => {
            if(response.pagination){
                setHasNext(response.pagination.HasNext!);
            }else{
                setHasNext(false);
            }

            // on récupère les signatures actuelles (en retirant les signatures du dernier groupe si elles existent)
            let newAllGroupedSignatures = getAllGroupWithoutLast(allGroupedSignatures);

            let newSignatures = response.data;
            // on vérifie si l'on doit injecter des signatures dans le dernier groupe
            // (étant donnée que l'API envoie par groupe de 10 signatures, 
            // on doit vérifier si le dernier groupe est complet ou non)
            if(allGroupedSignatures.length != 0 && allGroupedSignatures[allGroupedSignatures.length - 1].length != 0){
                // on récupère l'identifiant du dernier groupe
                const lastGroupId: string = allGroupedSignatures[allGroupedSignatures.length - 1][0].signGroupId;
                // on récupère les signatures du dernier groupe
                const lastGroup = newSignatures.filter((signature) => signature.signGroupId === lastGroupId);
                // on supprime les signatures du dernier groupe des nouvelles signatures
                newSignatures = newSignatures.filter((signature) => signature.signGroupId != lastGroupId);
                // on vérifie si le dernier groupe est complet
                if(lastGroup.length != 0){
                    // on concatène les signatures du dernier groupe avec les nouvelles signatures
                    const lastGroupChanged = allGroupedSignatures[allGroupedSignatures.length - 1].concat(lastGroup);
                    // on ajoute le groupe modifié dans les nouveaux groupes
                    newAllGroupedSignatures.push(lastGroupChanged);
                }else{
                    // sinon on ajoute le dernier groupe tel quel
                    newAllGroupedSignatures.push(allGroupedSignatures[allGroupedSignatures.length - 1]);
                }
            }

            setAllGroupedSignatures(
                addSignatureGroup(newAllGroupedSignatures, newSignatures)
            );
            resolve();
            })
        });
    }

    useEffect(() => {
        if(isInit && isFirstLoadingFinish){
            if(hasNext){
                getSignature(pageNumberApi);
            }
        }else if(isFirstLoadingFinish){
            setIsInit(true);
        }
    }, [pageNumberApi])

    useEffect(() => {
        const init = async () => {
            let initSignatures: SignatureResponse[] = [];
            let currentPageNumber: number = 1;
            let currentHasNext: boolean = true;
            // on récupère les signatures par groupe de 10, 
            // on s'arrête si on a récupéré 3 groupes de signatures ou si on a récupéré toutes les signatures
            while(currentHasNext && getNbSignGroup(initSignatures) < 3){
                // on récupère les signatures
                const response = await get<SignatureResponse[]>("/signature/getByWorkOrder/" + workOrderId, {CurrentPage: currentPageNumber})
                initSignatures = initSignatures.concat(response.data);
                currentPageNumber++;
                // on vérifie si on a encore des signatures à récupérer
                if(response.pagination){
                    currentHasNext = response.pagination.HasNext!;
                }else{
                    currentHasNext = false;
                }
            }
            setAllGroupedSignatures(
                addSignatureGroup([], initSignatures)
            );
            setPageNumberApi(currentPageNumber-1);
            setHasNext(currentHasNext);
            setIsFirstLoadingFinish(true);
        };

        init();
    }, [])

    useEffect(() => {
        setPageNumberMax(allGroupedSignatures.length);
    }, [allGroupedSignatures])

    useEffect(() => {
        if(isInit && allGroupedSignatures.length != 0){
            setCurrentSignatures(allGroupedSignatures[pageNumber - 1]);
        }
    }, [pageNumber, isInit])

    const nextPage = () => {
        const newPageNumber = pageNumber+1;
        if(hasNext){
            if(newPageNumber >= allGroupedSignatures.length - 1){
                setPageNumberApi(pageNumberApi + 1);
            }
            setPageNumber(newPageNumber);
        }else if(newPageNumber <= pageNumberMax){
            setPageNumber(newPageNumber);
        }
    }

    const previousPage = () => {
        const newPageNumber = pageNumber-1;
        if(newPageNumber >= 1){
            setPageNumber(newPageNumber);
        }
    }

    return [nextPage, previousPage, currentSignatures, pageNumber, pageNumberMax, hasNext] as const
};

export default useConsultSignature;