import React from "react";
import './LoadingSpinner.css';

const LoadingSpinner = () => {
    return (
        <div className="loadingio-spinner-spinner-2by998twmg8"><div className="ldio-yzaezf3dcmj">
        <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
        </div></div>
    );
}

export default LoadingSpinner;