import React, { createRef } from 'react';
import './Parameter.css';
import { GoBack} from './index.js';
import { AdminRoleImg, LeaveImg, PersonImg } from '../images/index.js';
import { AppTools, Role } from '../types.js';

type ParameterProps = {
    hideParameter: () => void,
    appTools: AppTools,
    roles: Role[]
}

const Parameter = (props: ParameterProps) => {
    const { hideParameter, appTools, roles } = props;
    const mainRef = createRef<HTMLDivElement>();

    const triggerHideParameter = () => {
        mainRef.current!.style.animationName = "slideout";
        setTimeout(() => {
            if(mainRef.current){
                mainRef.current!.style.opacity = "0";
            }
            hideParameter();
        },400);
    }

    const triggerDisconnect = () => {
        appTools.isLoading(true);
        appTools.disconnect()
        .then(() => {
            triggerHideParameter();
        })
        .finally(() => {
            appTools.isLoading(false);
        });
    }

    return (
        <div ref={mainRef} className='parameter-main'>
            <GoBack onGoBack={() => {triggerHideParameter()}} />

            <div className='parameter-element' onClick={triggerDisconnect}>
                <img src={LeaveImg} className='parameter-element-icon' />
                <p className='parameter-element-text'>Deconnexion</p>
            </div>
            {roles.length > 1 && (
                <div className='parameter-element' onClick={() => {hideParameter();appTools.displayRoleSelector(roles, true).catch(() => {});}}>
                    <img src={AdminRoleImg} className='parameter-element-icon' />
                    <p className='parameter-element-text'>Changer de rôle</p>
                </div>
            )}
        </div>
    );
}

export default Parameter;