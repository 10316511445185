import React, { useEffect, useState } from 'react';
import './WorkOrderDetail.css';
import { useNavigate, useParams } from 'react-router-dom';
import { ConsultWorkOrderDetail } from '../views';
import { SCAN_WORK_ORDER_ROUTE, WORK_ORDER_ROUTE } from '../route.ts';
import { ApiError, UserInfoResponse, WorkOrderDetailReponse } from '../api/responses.ts';
import { useRequest } from '../hooks/index.js';
import { BASE_URL} from '../const.ts';
import { AppTools, MessageViewType} from '../types.ts';

type WorkOrderDetailProps = {
    appTools: AppTools,
}

const WorkOrderDetail = (props: WorkOrderDetailProps) => {
    const navigate = useNavigate();
    const { idOrQrCodeValue } = useParams();
    const { appTools } = props;
    const [, , get] = useRequest(BASE_URL, props.appTools);

    const [model, setModel] = useState<WorkOrderDetailReponse | undefined>();
    const [userInfo, setUserInfo] = useState<UserInfoResponse | undefined>();

    useEffect(() => {
        // on vérifie ce qui est passé en paramètre
        const id: number = Number(idOrQrCodeValue);
        if(!isNaN(id)){
            // si c'est un nombre, alors cela signifie que c'est un id de bon
            get<WorkOrderDetailReponse>("/workOrders/" + id).then((responses) => {
                setModel(responses.data);
            }).catch((err: ApiError) => {
                if(err.code == 404){
                    props.appTools.showMessage(MessageViewType.NotFound, err.message);
                }else{
                    props.appTools.showMessage(MessageViewType.Error, err.message);
                }
                navigate(WORK_ORDER_ROUTE);
            });
        }else{
            // si ce n'est pas un nombre, alors cela signifie que c'est une valeur
            // de QRCode
            get<WorkOrderDetailReponse>("/signature/workOrder/getByQrCode/" + idOrQrCodeValue).then((responses) => {
                setModel(responses.data);
            }).catch((err: ApiError) => {
                if(err.code == 404){
                    props.appTools.showMessage(MessageViewType.NotFound, err.message);
                }else{
                    props.appTools.showMessage(MessageViewType.Error, err.message);
                }
                // on revient sur la route du scanner, puisque c'est le seul passage
                // qui donne un qrCode au lieu d'un id de bon
                navigate(SCAN_WORK_ORDER_ROUTE);
            });
        }

        get<UserInfoResponse>("/user/getCurrentInfo").then((response) => {
            setUserInfo(response.data);
        }).catch((err: ApiError) => {
            navigate(WORK_ORDER_ROUTE);
        });
    }, []);

    return (
        <>
           {model && userInfo && (
                <ConsultWorkOrderDetail 
                    workOrder={model}
                    userInfo={userInfo}
                    appTools={appTools}
                    onValidateSign={() => {
                        props.appTools.showMessage(MessageViewType.Success, "Signature validée");
                        navigate("/");
                    }}
                    onFailToLoad={() => navigate(WORK_ORDER_ROUTE)}
                    onGoBack={() => navigate(WORK_ORDER_ROUTE)}
                    />
           )}
        </>
    );
}

export default WorkOrderDetail;