import React, { ReactNode } from "react";
import './Placeholder.css';

type PlaceholderProps = {
    display?: ReactNode,
    index: number,
}

const Placeholder = (props: PlaceholderProps) => {
    return (
        <div className='placeholder-main' style={{zIndex: 5000+props.index}}>
            {props.display}
        </div>
    );
};

export default Placeholder;