import React, { useEffect, useState } from 'react';
import { ConnectState, RequestType } from '../types.ts';
import axios from 'axios';
import { useTimer } from 'react-timer-hook';
import { BASE_URL } from '../const.ts';
import { ApiError, TokenReponse } from '../api/responses.ts';


const useConnect = (onTokenExpire: () => void) => {
    const [state, setState] = useState<ConnectState>(ConnectState.NotConnected);
    const [currentUserId, setCurrentUserId] = useState<string | null>(null);
    const [expirationWithoutInactivity, setExpirationWithoutInactivity] = useState<number>(0);

    const { restart, pause } = useTimer({ expiryTimestamp: new Date(), autoStart: false, onExpire: () => {tokenExpire()} });

    const reloadExpirationTimer = (newExpiration?: number) => {
        // initialise le timer de deconnexion
        const time = new Date();
        if(newExpiration){
            setExpirationWithoutInactivity(newExpiration!);
            time.setSeconds(time.getSeconds() + newExpiration);
        }else{
            time.setSeconds(time.getSeconds() + expirationWithoutInactivity);
        }

        restart(time);
    }

    const checkTokenValid = (token: any) : Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            axios.post(BASE_URL + "/user/check", {
                token: token
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
            .then((res) => {
                const model = res.data as TokenReponse;
                reloadExpirationTimer(model.secWithoutActivityBeforeExpiration);
                setState(ConnectState.Connected);
                resolve(true);

            }).catch(() => {resolve(false)})
        });
    }

    const tryConnect = (pin: string): Promise<TokenReponse> => {
        return new Promise<TokenReponse>((resolve, reject) => {
            axios.post(BASE_URL + "/user/authentificate", {
                identification: currentUserId,
                password: pin
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
            .then(res => {
                const model = res.data as TokenReponse;
                reloadExpirationTimer(model.secWithoutActivityBeforeExpiration);
                setState(ConnectState.Connected);
                resolve(model);
            })
            .catch(res => {
                reject(res.response.data as ApiError);
            })
        });
    };

    const tokenExpire = () => {
        onTokenExpire();
        setState(ConnectState.HaveUserId);
    }

    const isExistUserId = (userId: string) : Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            // pour des questions de sécurité, l'API n'est pas programmé pour vérifier 
            // si le QRCode donnée est fonctionnel ou non.
            // Pour éviter que les attaquants saches s'ils ont trouvés un identifiant correct

            setCurrentUserId(userId);
            resolve(true);
            setState(ConnectState.HaveUserId);
        });
    }

    const disconnect = (token: any) : Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            axios.post(BASE_URL + "/user/logout", {
                token: token
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
            .then(res => {
                resolve(true);
                pause();
                setState(ConnectState.NotConnected);
            })
            .catch(res => {
                const apiReponse = res.response.data as ApiError;
                if(apiReponse.code == 401){
                    pause();
                    resolve(true);
                }else{
                    reject();
                }
                setState(ConnectState.NotConnected);
            })
        });
    }

    const cancelConnect = () => {
        if(state == ConnectState.HaveUserId){
            setState(ConnectState.NotConnected);
        }
    }

    return [state, tryConnect, isExistUserId, disconnect, cancelConnect, reloadExpirationTimer, checkTokenValid] as const;
}

export default useConnect;