import React, { useEffect, useState } from "react";
import './OnePhoneSignature.css'
import { AppTools, ConnectState, MessageViewType, Role, SignState } from "../../types.ts";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ROOT_ROUTE, SIGN_TYPE_ARG_ROUTE, WORK_ORDER_ROUTE, getRouteName } from "../../route.ts";
import { BASE_URL, COOKIES_TOKEN } from "../../const.ts";
import { useConnect, useRequest } from "../../hooks";
import { ApiError, SignatureStateResponse, UserInfoResponse, WorkOrderDetailReponse } from "../../api/responses.ts";
import { ConsultWorkOrderDetail, SelectRole, TmpLoginOnePhone } from "../../views";
import Cookies from "universal-cookie";
import { EnterPin } from "../index.js";

type OnePhoneSignatureProps = {
    appTools: AppTools
}

const cookies = new Cookies(null, { path: '/' });

const OnePhoneSignature = (props: OnePhoneSignatureProps) => {
    const {appTools} = props;
    // route et navigation
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { qrCodeValue } = useParams();
    const signType: string | null = searchParams.get(getRouteName(SIGN_TYPE_ARG_ROUTE));
    
    // token temporaire pour la signature
    const [tmpToken, setTmpToken] = useState<string | undefined>();
    const [ownerUserRole, setOwnerUserRole] = useState<Role | undefined>();
    const [ownerUserLabelle, setOwnerUserLabelle] = useState<string | undefined>();
    const [userInfo, setUserInfo] = useState<UserInfoResponse | undefined>();
    const [model, setModel] = useState<WorkOrderDetailReponse | undefined>();
    const [returnDeviceToOwner, setReturnDeviceToOwner] = useState<boolean>(false);
    const [isSignatureValidated, setIsSignatureValidated] = useState<boolean>(false);

    // détermine le role d'utilisateur qui doit signer
    const [currentRoleToSign, setCurrentRoleToSign] = useState<Role | undefined>();

    // fonction appelé quand le token expire
    const onTokenExpire = () => {
        appTools.showMessage(MessageViewType.Warning, "Deconnexion pour cause d'inactivité");
        setTmpToken(undefined);
    }

    // connecteur temporaire
    const [state, privateTryConnect, isExistUserId, privateDisconnect, cancelConnect, reloadExpirationTimer, checkTokenValid] = useConnect(onTokenExpire);
    // requête
    const [,, get, deleteR] = useRequest(BASE_URL, props.appTools, tmpToken);

    const disconnect = () : Promise<boolean> => {
        if(userInfo){
            setUserInfo(undefined);
        }
        return new Promise<boolean>((resolve) => {
            privateDisconnect(tmpToken).then(() => {
                resolve(true);
            }).catch(() => {
                resolve(false);
            }).finally(() => {
                setTmpToken(undefined);
            });
        });
    }
    
    const tryConnect = (pin: string): Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            privateTryConnect(pin).then((token) => {
                setTmpToken(token.token);
                resolve(true);
            }).catch((err) => {
                const apiErr: ApiError = err as ApiError
                if(apiErr.code === 403){
                    resolve(false); 
                }else{
                    reject();
                }
            });
        });
    }

    const tryConnectToOwner = (pin: string): Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            privateTryConnect(pin).then((token) => {
                get<UserInfoResponse>("/user/getCurrentInfo", undefined, token.token).then((response) => {
                    if(response.data.userLabelle !== ownerUserLabelle){
                        appTools.showMessage(MessageViewType.Warning, "Vous n'êtes pas le propriétaire de l'appareil");
                        disconnect();
                    }else{
                        if(isSignatureValidated){
                            appTools.showMessage(MessageViewType.Success, "Signature validée");
                        }
                        navigate(WORK_ORDER_ROUTE);
                    }
                }).catch((err) => {
                    appTools.showMessage(MessageViewType.Warning, 
                        "Impossible de récupérer les informations de l'utilisateur", "Veuillez reesaayer");
                }).finally(() => {
                    resolve(true);
                });

            }).catch((err) => {
                const apiErr: ApiError = err as ApiError
                if(apiErr.code === 403){
                    resolve(false); 
                }else{
                    reject();
                }
            });
        });
    }

    // fonction afin de vérifier si l'utilisateur est connecté
    const checkUserConnected = () => {
        checkTokenValid(tmpToken).then((res) => {
            if(!res){
                disconnect();
            }
        });
    }

    // affiche un selecteur permettant de choisir un role
    const displayRoleSelector = (roles: Role[], forceSelect?: boolean) : Promise<Role> => {
        return new Promise<Role>((resolve, reject) => {
            appTools.pushDisplay(
                <SelectRole 
                    roles={roles} 
                    onSelect={(role) => {
                        appTools.popDisplay();
                        resolve(role);
                    }} 
                    onCancel={() => {
                        appTools.popDisplay();
                        reject();
                    }} />
            );
        });
    }

    // outils de l'application temporaire qui va être passé au 
    // composant de connexion et de visualisation du bon de travail
    const tmpAppTools: AppTools = {
        showMessage: appTools.showMessage,
        isLoading: appTools.isLoading,
        disconnect: disconnect,
        pushDisplay: appTools.pushDisplay,
        popDisplay: appTools.popDisplay,
        reloadTimerExpiration: reloadExpirationTimer,
        displayInput: appTools.displayInput,
        checkUserConnected: checkUserConnected,
        displayRoleSelector: displayRoleSelector
    };

    const triggerCancelAndGoBack = (goBackToWorkOrderRoute: boolean = true) => {
        appTools.isLoading(true);
        setCurrentRoleToSign(undefined);
        disconnect().catch().finally(() => {
            deleteR("/signature/" + model!.workId).finally(() => {
                appTools.isLoading(false);
                if(goBackToWorkOrderRoute){
                    navigate(WORK_ORDER_ROUTE);
                }
            })
        });
    }

    const continueSignature = (signStates: SignState[]) => {
        if(isSignatureFinished(signStates)){
            // tout les roles ont signé
            // il faut s'assurer que le téléphone revienne au propriétaire
            setIsSignatureValidated(true);
            triggerReturnDeviceToOwner();
        }else{
            // on détermine le role qui doit signer
            setCurrentRoleToSign(getNextRoleToSign(signStates));
        }
    }

    const refreshSignState = (workOrderId: number): Promise<SignState[]> => {
        return new Promise<SignState[]>((resolve, reject) => {
            get<SignatureStateResponse>(
                "/signature/state?workIdConcerned=" + workOrderId.toString() + "&signatureType=" + signType, 
                undefined,
                // on force l'utilisation du token de l'utilisateur propriétaire de l'appareil
                cookies.get(COOKIES_TOKEN)
        ).then(response => {
                let results: SignState[] = [];
                response.data.roleNeeded.forEach(role => {
                    results.push({role:role, isSigned: response.data.alreadySigned.includes(role)});
                });
                resolve(results);
                continueSignature(results);
            }).catch((err) => {
                reject();
            })
        });
    }

    const signatureValidated = (): void => {
        setCurrentRoleToSign(undefined);
        // une fois la signature validée on déconnecte l'utilisateur temporaire
        disconnect().finally(() => {
            // puis on rafraichit l'état de la signature
            // afin de lister les roles restant qui doivent signer
            refreshSignState(model!.workId).catch(() => {
                triggerCancelAndGoBack();
            });
        })
    }

    const isSignatureFinished = (signStates: SignState[]): boolean => {
        let isFinished = true;
        for (let index = 0; index < signStates.length; index++) {
           if(!signStates[index].isSigned){
                isFinished = false;
                break;
           }
        }
        return isFinished;
    }

    const getNextRoleToSign = (signStates: SignState[]): Role => {
        for (let index = 0; index < signStates.length; index++) {
            if(!signStates[index].isSigned){
                return signStates[index].role;
            }
        }
        throw new Error("No role to sign");
    }

    const triggerReturnDeviceToOwner = (): void => {
        if(ownerUserRole && ownerUserLabelle){
            setReturnDeviceToOwner(true);
        }else{
            // par sécurité on déconnecte l'utilisateur
            // s'il y a un problème
            navigate(ROOT_ROUTE);
            appTools.disconnect();
        }
    }


    // ON DEMARRE ICI
    // vérifie si tout les paramètres et les arguments de la route sont bien définis
    useEffect(() => {
        if(qrCodeValue == null || signType == null){
            navigate(WORK_ORDER_ROUTE);
        }else{
            // on récupère le bon de travail concerné par la signature
            get<WorkOrderDetailReponse>("/signature/workOrder/getByQrCode/" + qrCodeValue).then((responses) => {
                setModel(responses.data);
                // on récupère l'utilisateur qui à déclarer la signature
                get<UserInfoResponse>("/user/getCurrentInfo").then((response) => {
                    setOwnerUserLabelle(response.data.userLabelle);
                    // récupère la liste des roles qui doivent signer
                    refreshSignState(responses.data.workId).then((roles) => {
                        // récupère le seul rôle ayant signé
                        // c'est le rôle de l'utilisateur qui à déclarer le bon de travail
                        for (let index = 0; index < roles.length; index++) {
                            if(roles[index].isSigned){
                                setOwnerUserRole(roles[index].role);
                                break;
                            }
                        }
                    }).catch((err) => {
                        // s'il y a un problème on arrête et on considère que 
                        // le cycle de signature n'a pas pu être initialisé
                        triggerCancelAndGoBack();
                    });
                }).catch((err) => {
                    // s'il y a un problème on arrête et on considère que 
                    // le cycle de signature n'a pas pu être initialisé
                    triggerCancelAndGoBack();
                });

            }).catch((err: ApiError) => {
                triggerCancelAndGoBack();
            });
            
        }
    }, [])

    // ce useEffect vérifie une fois la connexion établie si l'utilisateur connecté
    // possède le rôle qui doit signer
    useEffect(() => {
        if(tmpToken){
            get<UserInfoResponse>("/user/getCurrentInfo").then((response) => {
                // vérifie que l'utilisateur possède le role
                if(response.data.userRoleIds.includes(currentRoleToSign!)){
                    setUserInfo(response.data);
                }else{
                    disconnect();
                    appTools.showMessage(MessageViewType.Warning, "Vous n'avez pas le rôle pour signer");
                }
            }).catch((err) => {
                disconnect();
            });
        }else{
            // on est déconnecté
            setUserInfo(undefined);
        }
    }, [tmpToken])

    return (
        <>
            {/* On arrive ici quand un rôle à été désigner comme devant signer */}
            {currentRoleToSign && state == ConnectState.NotConnected && (
                <TmpLoginOnePhone 
                    onCanceled={() => {
                        triggerCancelAndGoBack(false);
                        triggerReturnDeviceToOwner();
                    }}
                    isExistUserId={isExistUserId} 
                    appTools={tmpAppTools} 
                    neededRole={currentRoleToSign} />
            )}
            {/* L'utilisateur a scanné son QRCode, maintenant il inscrit son code PIN */}
            {currentRoleToSign && state == ConnectState.HaveUserId && (
                <EnterPin 
                    appTools={tmpAppTools} 
                    tryConnect={tryConnect} 
                    cancelConnect={cancelConnect} />
            )}
            {currentRoleToSign && state == ConnectState.Connected && userInfo && model && (
                <ConsultWorkOrderDetail 
                    appTools={tmpAppTools}
                    customToken={tmpToken}
                    userInfo={userInfo}
                    workOrder={model}
                    onFailToLoad={() => triggerCancelAndGoBack()}
                    onGoBack={() => disconnect()}
                    onValidateSign={() => {signatureValidated()}}
                />
            )}

            {/* la signature à été effectuer (ou annulé), il faut vérifier que l'appareil revienne à l'utilisateur*/}
            {returnDeviceToOwner && ownerUserRole && state == ConnectState.NotConnected && (
                <TmpLoginOnePhone 
                    onCanceled={
                        () => {
                            appTools.disconnect();
                            navigate(ROOT_ROUTE);
                        }
                    }
                    isReturnDevice={true}
                    isExistUserId={isExistUserId} 
                    appTools={tmpAppTools} 
                    neededRole={ownerUserRole} />
            )}
            {returnDeviceToOwner && ownerUserRole && state == ConnectState.HaveUserId && (
                <EnterPin 
                    appTools={tmpAppTools} 
                    tryConnect={tryConnectToOwner} 
                    cancelConnect={cancelConnect} />
            )}
        </>
    )
}

export default OnePhoneSignature;